import "less/views/widgets/staticMenuDropdown";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import locale from "js/main/locale";
import Dropdown from "./Dropdown";

export default class StaticMenuDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownInit = this.dropdownInit.bind(this);
  }

  dropdownInit(that) {
    let title = ReactDOM.findDOMNode(this.refs.title);
    title.addEventListener("click", () => that.toggle());
  }

  render() {
    const title = (
      <div className="title headerBtn" tabIndex={0} ref="title">
        <span className="icon icon-menu" />
      </div>
    );

    const content = (
      <ul key="list">
        <li>
          <a href="/gastlogin">{locale.guestLogin}</a>
        </li>
        <li>
          <a href={`/page/${locale.lang}/wohnen-auf-zeit`}>
            {locale.trustAndSecurity}
          </a>
        </li>
        <li>
          <a href="/gastgeberlogin">{locale.hostLogin}</a>
        </li>
        <li>
          <a href={`/page/${locale.lang}/ueber-uns`}>{locale.weAboutUs}</a>
        </li>
        <li>
          <a href={`/page/${locale.lang}/kontakt`}>{locale.contact}</a>
        </li>
        <li>
          <a href={`/page/${locale.lang}/buchungsablauf`}>
            {locale.bookingProcess}
          </a>
        </li>
        <li>
          <a href={`/page/${locale.lang}/besichtigung`}>{locale.visit}</a>
        </li>
        <li>
          <a href={`/page/${locale.lang}/gastgeberfaq`}>{locale.hostInfo}</a>
        </li>
        <li>
          <a href={`/page/${locale.lang}/relocation`}>
            {locale.relocation}
          </a>
        </li>
        <li>
          <a href={`/blog`}>BnB Blog</a>
        </li>
      </ul>
    );
    return (
      <Dropdown
        className="staticMenuDropdown"
        title={title}
        content={content}
        fixed
        init={this.dropdownInit}
        ref="dropdown"
      />
    );
  }
}
