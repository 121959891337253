import { render } from "less";

/**
 * UrbanBnB - Blog Posts
 *
 * Handles loading and rendering of content from UrbanBnB-Blog
 */
export default class BlogPosts {
    constructor(apiUrl, templateSelector, target) {
        this.apiUrl = apiUrl;
        this.templateSelector = templateSelector;
        this.target = document.querySelector(target);
        this.template = null;
        this.defaultLang = "de";

        this.initial = true;
        this.limit = 3;
        this.offset = 0;

        this.moreLinkElement = null;

        this.pending = false;

        this.registerIsInViewportHandler();
    }

    setMoreLinkElement(selector) {
        this.moreLinkElement = document.querySelector(selector);
        if (this.moreLinkElement) {
            this.moreLinkElement.addEventListener("click", (e) => {
                e.preventDefault();
                this.getMorePosts();
                return false;
            })
        }
    }

    registerIsInViewportHandler() {
        if (this.target) {
            document.addEventListener(
                "scroll",
                () => {
                    if (this.initial && this.isInViewport(this.target)) {
                        this.initial = false;
                        this.getPostsFrom();
                    }
                },
                { passive: true }
            );
        }
    }

    isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return rect.top <= (window.innerHeight || document.documentElement.clientHeight);
    }

    getCurrentLang() {
        const urlParams = new URLSearchParams(window.location.search);
        this.lang = urlParams.get("lang");

        return this.lang ? this.lang : this.defaultLang;
    }

    getMorePosts() {
        let currentOffset = this.offset;
        this.offset = this.offset + (this.limit + 1);
        this.getPostsFrom().catch(() => {
            this.offset = currentOffset;
        })
    }

    getPostsFrom() {
        this.pending = true;

        const url = new URL(this.apiUrl + "entry");
        let params = {
            display: "date,topics,teaser",
            lang: this.getCurrentLang(),
            limit: this.limit,
            offset: this.offset,
        };
        url.search = new URLSearchParams(params).toString();

        return fetch(url)
            .then((response) => response.json())
            .then((json) => {
                json.forEach((post) => {
                    this.renderPost(post);
                });

                this.pending = false;
            });
    }

    renderPost(post) {
        if ("content" in document.createElement("template")) {
            const blogPostTemplate = document.querySelector(this.templateSelector);

            // Clone the new post and insert it into the target
            let clone = document.importNode(blogPostTemplate.content, true);
            clone.querySelector("img").src = "https://www.nd-bed-breakfast.de" + post.teaserImage;

            let date = new Date(post.date);
            clone.querySelector(".createdAt").textContent = date.toLocaleDateString(this.getCurrentLang(), {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            clone.querySelector(".headline").textContent = post.title;
            clone.querySelector(".text").textContent = post.teaserText;
            const slug = post.title.toLowerCase().replace(/ /g, "-").
                replace("ä", "ae").replace("ö", "oe").replace("ü", "ue").
                replace("Ä", "Ae").replace("Ö", "Oe").replace("Ü", "Ue").
                replace("ß", "ss").
                replace(/[^\w-]+/g, "");

            clone.querySelector(".more-link").href = "https://www.urbanbnb.de/blog/" + slug + "/" + post.id;

            // Render new post into target
            this.target.appendChild(clone);

        } else {
            // Find another way to add the rows to the table because
            // the HTML template element is not supported.
        }
    }
}
